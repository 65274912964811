<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="top-bg s-flex-center">阳光配送</div>

        <div class="body-box">
          <div class="bottom-box">
            <div class="box">
              <tableLeft>
                <venue-personnelhealth1 slot="content" style="margin-top: 22px;"></venue-personnelhealth1>
              </tableLeft>
              
            </div>
            <div class="box">
              <tableLeft2>
                <venue-personnelhealth2 slot="content" style="margin-top: 22px;"></venue-personnelhealth2>
              </tableLeft2>
            </div>
            <div class="box">
              <food-chain style="margin-bottom: 47px;"></food-chain>
              <commodity></commodity>
              <application-scene style="margin-top: 22px;"></application-scene>
            </div>
            <!-- <div class="box ">
              <venue-personnelhealth style="margin-bottom: 22px;"></venue-personnelhealth>

            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";

import tableLeft from "./indexComponent/tableLeft";
import tableLeft2 from "./indexComponent/tableLeft2";

import foodChain from "./cz/foodChain/index.vue";
import commodity from "./cz/commodity/index";
import applicationScene from "./cz/applicationScene/index";
import venuePersonnelhealth from "./cz/venuePersonnelhealth/index";
import venuePersonnelhealth1 from "./cz/venuePersonnelhealth/index-1";
import venuePersonnelhealth2 from "./cz/venuePersonnelhealth/index-2";
export default {
  mixins: [drawMixin],
  data() {
    return {
      loading: true
    };
  },
  components: {
    foodChain,
    commodity,
    applicationScene,
    venuePersonnelhealth,
    venuePersonnelhealth1,
    venuePersonnelhealth2,
    tableLeft,
    tableLeft2
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.top-bg {
  background: url("../assets/image/index/top-bg.png") no-repeat center;
  height: 82px;
  width: 100%;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #01b5ff;
}

.box {
  padding-right: 50px;
}
</style>
